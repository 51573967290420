<template>
  <div>
    <v-sheet
      :height="height"
      :width="width"
      color="transparent"
      style="position: relative"
    >
      <v-img
        class="avatar"
        :max-height="height"
        :max-width="width"
        :src="avatarShadow"
      ></v-img>
      <v-img
        class="avatar"
        :max-height="height"
        :max-width="width"
        :src="avatarBody"
      >
        <template v-slot:placeholder>
          <v-img
            class="avatar"
            :max-height="height"
            :max-width="width"
            :src="require('@/assets/entity/load.png')"
          />
        </template>
      </v-img>
      <v-img
        class="avatar"
        :max-height="height"
        :max-width="width"
        :style="
          'filter : opacity(0.5) drop-shadow(0 0 0 #' + avatarColor + ' )'
        "
        :src="avatarBody"
      ></v-img>
      <v-img
        class="avatar"
        :max-height="height"
        :max-width="width"
        :src="avatarCheek"
      ></v-img>
      <v-img
        class="avatar"
        :max-height="height"
        :max-width="width"
        :src="avatarOutline"
      >
        <template v-slot:placeholder>
          <v-img
            class="avatar"
            :max-height="height"
            :max-width="width"
            :src="require('@/assets/entity/load.png')"
          />
        </template>
      </v-img>
      <v-img
        class="avatar"
        :max-height="height"
        :max-width="width"
        :src="avatarMouth"
      ></v-img>
      <v-img
        class="avatar"
        :max-height="height"
        :max-width="width"
        :src="avatarEye"
      ></v-img>
      <v-img
        class="avatar"
        :max-height="height"
        :max-width="width"
        :src="avatarHair"
      ></v-img>
      <v-img
        v-if="selectedItem && selectedItem.category == 'hat'"
        class="avatar"
        :max-height="height"
        :max-width="width"
        :src="JSON.parse(selectedItem.images).apparel.url"
      ></v-img>
      <v-img
        v-if="avatarHat && (selectedItem && selectedItem.category != 'hat') || (avatarHat && !selectedItem) && !selectedHat"
        class="avatar"
        :max-height="height"
        :max-width="width"
        :src="avatarHat"
      ></v-img>
      <v-img
        v-if="selectedHat"
        class="avatar"
        :max-height="height"
        :max-width="width"
        :src="JSON.parse(selectedHat.images).apparel.url"
      ></v-img>
      <v-img
        v-if="user && pick.Pick && previewHat == null"
        class="avatar"
        :max-height="height"
        :max-width="width"
        :src="
          JSON.parse(
            hatData.filter(function (entry) {
              return entry.key == pick.Pick;
            })[0].images
          ).apparel.url
        "
      ></v-img>
      <v-img
        v-if="previewHat != null"
        class="avatar"
        :max-height="height"
        :max-width="width"
        :src="
          JSON.parse(
            hatData.filter(function (entry) {
              return entry.key == previewHat;
            })[0].images
          ).apparel.url
        "
      ></v-img>
    </v-sheet>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    avatar: {
      type: String,
    },
    width: {
      type: Number,
      default: 40,
    },
    height: {
      type: Number,
      default: 40,
    },
    changeHat: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Boolean,
      default: false,
    },
    previewHat: {
      type: String,
      default: null,
    },
    selectedItem: {
      type: Object,
      default: null,
    },
    selectedHat: {
      type: Object,
      default: null,
    },
  },
  computed: mapState({
    pick: (state) => state.pick.data,
    skinData: (state) => state.skin.data,
    hatData: (state) => state.apparel.data,
  }),
  watch: {
    avatar: function () {
      this.getAvatar();
    },
  },
  data: () => ({
    renderAvatar: null,
    avatarOutline: null,
    avatarMouth: null,
    avatarBody: null,
    avatarShadow: null,
    avatarEye: null,
    avatarHair: null,
    avatarCheek: null,
    avatarColor: null,
    avatarHat: null,
    mouth: [],
    body: [],
    outline: [],
    shadow: [],
    eye: [],
    hair: [],
    cheek: [],
    hat: [],
    version: null,
    selectedStudent: null,
    student: null,
    code: null,
    skin: [],
  }),
  created() {},
  mounted() {
    this.getAvatar();
  },
  methods: {
    getAvatar() {
      this.renderAvatar = JSON.parse(this.avatar);
      this.version = this.renderAvatar.charKey;
      if (this.version == "mochi_v3") {
        this.skin = this.skinData;
        this.selectSkin();
      }
    },
    selectSkin() {
      this.body = this.skin.body;
      this.outline = this.skin.outline;
      this.mouth = this.skin.mouth;
      this.shadow = this.skin.shadow;
      this.eye = this.skin.eye;
      this.hair = this.skin.hair;
      this.cheek = this.skin.cheek;
      var outline = this.renderAvatar.body;
      var mouth = this.renderAvatar.mouth;
      var hat = this.renderAvatar.hat;
      var avatarOutline = this.outline.filter(function (entry) {
        return entry.character == outline;
      });
      this.avatarOutline = JSON.parse(avatarOutline[0].images).F0.url;
      var avatarBody = this.body.filter(function (entry) {
        return entry.character == outline;
      });
      this.avatarBody = JSON.parse(avatarBody[0].images).F0.url;
      var avatarMouth = this.mouth.filter(function (entry) {
        return entry.character == mouth;
      });
      this.avatarMouth = JSON.parse(avatarMouth[0].images).F0.url;

      if (hat) {
        var avatarHat = this.hatData.filter(function (entry) {
          return entry.key == hat;
        });
        this.avatarHat = JSON.parse(avatarHat[0].images).apparel.url;
      }

      this.avatarShadow = JSON.parse(this.shadow[0].images).F0.url;
      this.avatarEye = JSON.parse(this.eye[0].images).F0.url;
      this.avatarHair = JSON.parse(this.hair[0].images).F0.url;
      this.avatarCheek = JSON.parse(this.cheek[0].images).F0.url;
      this.avatarColor = this.renderAvatar.pColor;
    },
  },
};
</script>

<style scoped>
.avatar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>